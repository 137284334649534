.custom-list-item {
  cursor: pointer;

  ion-grid {
    ion-col {
      display: flex;
      align-items: center;
    }
  }
  
  &:hover {
    ion-grid {
      background-color : rgba(138, 138, 138, 0.102); 
    }
  }
}