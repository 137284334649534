.status-item{
  --background: rgba(0, 0, 0, 0);
  --color: white;
  --border-style: 0;
  font-size: 1.2em;
}

.status-background{
  background-color: rgb(213, 205, 205); 
}

.center-text {
  display: flex;
  align-items: center;
}

.job-button{
  margin-right: 3%;
  margin-top: 0.8%;
}

.header-icon{
  font-size: 2em;
  margin-right: 1.5%;
  margin-left: 1.5%;
  cursor: pointer;
}

ion-datetime {
  height: auto;
  width: auto;

  max-width: 350px;
}

.date-populated {
  --background: rgba(138, 138, 138, 0.068);
  --placeholder-color: black;
  --placeholder-opacity: 1;

}


.date-notpopulated {
  color: rgb(138, 138, 138);

  &:hover {
    --background: rgba(138, 138, 138, 0);
    color: rgba(138, 138, 138, 0);
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-gap: 0 10px;
}

ion-modal-dt {
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
}

ion-modal-dt ion-datetime {
  height: 382px;
}