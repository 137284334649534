
// Styling for Searchbar (subject to change, styling added for example)
// See CSS Custom Properties 
#search-bar{
  --border-radius: 8px;
  --box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.14);
}

#logout-button{
  cursor: pointer;
  --padding-bottom: 0;
  --padding-start: 0px;
  padding-left: 10px;
  font-size: 1.4em;
}

.dark-icon{
  font-size: 1.1em;
}

#title{
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 1.3em;
}