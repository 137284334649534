
ion-chip {
  --background: transparent;
  font-family: monospace;
  font-size: .75em;
  font-weight: bold;
  min-width: 100%;
  text-align: center;
  margin: auto;
  // padding: 0;
};

