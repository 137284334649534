.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.avatar {
  margin: auto;
  height: 100px;
  width: 100px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.profile-form {
  margin: auto;
  max-width: 50%;
}

#profile-edit-btn {
  margin-top: 20px;
  margin: auto;
}

.edit-profile-icon {
  cursor: pointer;
  height:25px;
  width: 25px;
  margin: auto;
}



