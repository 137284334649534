.button-group {
  display: flex; 
  margin-bottom: 1rem; 
  margin-right: 15%; 
  margin-left: 15%; 
  justify-content: space-between;
  .job-button {
    --background: linear-gradient(320deg,#DAD7D3 88%, #FFB347 50%);
    --opacity: .70;
    --ripple-color: #FFB347;
  }
  .company-button {
    --background: linear-gradient(320deg,#DAD7D3 88%, #67AFE0 50%);
    --opacity: .70;
    --ripple-color: #67AFE0;
  }
  .contact-button {
    --background: linear-gradient(320deg,#DAD7D3 88%, #F43E5C 50%);
    --opacity: .70;
    --ripple-color: #F43E5C;
  }
  .tab-button {
    width: 10rem;
    color: black;
    font-weight: 600;
    margin: .1rem;
    height: 2.8rem;
    --border-color: black;
    --border-width: 1px;
    --border-radius: 9px;
    --border-style: solid;
  }
  #active {
    --opacity: 1;
    --border-width: 2px;
  }
  .add-button {
    width: 100%;
  }
  .dark-job{
    color: white;
    --background: linear-gradient(320deg,#53514d 88%, #FFB347 50%);
    --border-color: white;
    --ripple-color: #FFB347;
  }
  .dark-company{
    color: white;
    --background: linear-gradient(320deg,#53514d 88%, #67AFE0 50%);
    --border-color: white;
    --ripple-color: #67AFE0;
  }
  .dark-contact{
    color: white;
    --background: linear-gradient(320deg,#53514d 88%, #F43E5C 50%);
    --border-color: white;
    --ripple-color: #F43E5C;
  }
  .locked {
    --background: rgba(128, 128, 128, 0.479);
  }
}

.custom-input {
  --background : rgba(138, 138, 138, 0.068); 
}

.edit-form-icon-unlocked {
  cursor: pointer;
  height: 33px;
  width: 33px;
  margin: auto;
  position: fixed;
  left: .45rem;
  bottom: 1rem;
  background-color: #f95838c0;
  border-radius: 50%;
  border: solid white 2px;
  color: white;
  padding: .3rem;
}

.edit-form-icon-locked {
  cursor: pointer;
  height: 33px;
  width: 33px;
  margin: auto;
  position: fixed;
  left: .45rem;
  bottom: 1rem;
  border-radius: 50%;
  border: solid rgba(130, 130, 130, 0.50) 2px;
  padding: .3rem;
}